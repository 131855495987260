<template>

  <AtomsTextLink v-if="props.item.page" class="warelist__item" :to="props.item.page.url" :target="props.linkTarget" @click="selectItem">

    <!-- standard -->

    <div v-if="props.type !== 'recent' && props.type !== 'light' && props.type !== 'light-single'" class="warelist__item__standard">

      <!-- image -->

      <div class="warelist__item__image">
        <MoleculesGlobalWareImage :ware-name="props.item.name" :image-id="props.item.image?.id" suffix="pd" class="mx-auto" :is-360="props.item.image?.type === 1 && !!props.item.image?.parameters" />
      </div>

      <!-- variant groups images -->

      <div class="absolute right-4 top-4 space-y-1">
        <template v-if="props.item.variantGroups?.length > 1" v-for="(item, index) in props.item.variantGroups">
          <div v-if="index < 2 && item.wareImage" class="warelist__item__additional-image">
            <MoleculesGlobalWareImage :ware-name="props.item.name" :image-id="item.wareImage.id" suffix="" />
          </div>
          <AtomsImagesIcon class="block rotate-90" v-if="index === 3" icon="arrow-right-carousel" />
        </template>
      </div>

      <!-- badges -->

      <div class="warelist__item__badges">
        <OrganismsBlocksBadges :ware-item="props.item" style="font-size: 0.85em;" :free-shipping-visible="true" />
      </div>

      <!-- name -->

      <component :is="props.elementNameOverride ?? (props.type === 'article' ? 'div' : 'h2')" class="warelist__item__name">{{ transformName(props.item.name) }}</component>

      <div class="warelist__item__info">

        <!-- price -->

        <strong class="warelist__item__price">
          {{ getPriceLabel(props.item) }}
        </strong>

        <!-- price recommended -->

        <strong v-if="getPriceRecLabel(props.item)" class="warelist__item__price-rec">
          {{ getPriceRecLabel(props.item) }}
        </strong>

        <!-- availability -->

        <strong class="warelist__item__availability">
          {{ getAvailabilityLabel(props.item) }}
        </strong>
      </div>
    </div>

    <!-- light -->

    <template v-else-if="props.type === 'light' || props.type === 'light-single'">
      <div class="flex gap-x-8 items-center p-4 text-left border border-neutral-500" :class="{ 'flex-1 bg-white': props.type === 'light', 'flex-wrap max-w-[500px] py-3 -mt-5 tablet:flex-nowrap': props.type === 'light-single' }">
        <MoleculesGlobalWareImage :ware-name="props.item.name" :image-id="props.item.image?.id" suffix="pd" class="w-[100px]" :class="{ 'w-auto mx-auto tablet:w-[150px]': props.type === 'light-single' }" :wrapper-class="{ 'flex-1 tablet:flex-none': props.type === 'light-single' }" :is-360="props.item.image?.type === 1 && !!props.item.image?.parameters" />
        <div class="flex flex-col">

          <!-- name -->

          <component :is="props.elementNameOverride ?? (props.type === 'light' ? 'h2' : 'strong')" class="warelist__item__name">{{ transformName(props.item.name) }}</component>

          <div class="warelist__item__info">

            <!-- price -->

            <span class="warelist__item__price">
              {{ getPriceLabel(props.item) }}
            </span>

            <!-- price recommended -->

            <strong v-if="getPriceRecLabel(props.item)" class="warelist__item__price-rec">
              {{ getPriceRecLabel(props.item) }}
            </strong>

            <!-- availability -->

            <strong class="warelist__item__availability">
              {{ getAvailabilityLabel(props.item) }}
            </strong>
          </div>

          <MoleculesButtonsButton v-if="props.type === 'light-single'" class="mt-6" :dummy="true" :arrow-right="true">{{ $t('Zobrazit detail') }}</MoleculesButtonsButton>
        </div>
      </div>
    </template>

    <!-- image only -->

    <template v-else>
      <MoleculesGlobalWareImage :ware-name="props.item.name" :image-id="props.item.image?.id" suffix="pd" class="w-[160px] mx-auto" :is-360="props.item.image?.type === 1 && !!props.item.image?.parameters" />
    </template>
  </AtomsTextLink>
</template>
<script setup>

const props = defineProps({
  type: String,
  item: Object,
  index: Number,
  linkTarget: String,
  listId: Number,
  listName: String,
  elementNameOverride: String
});

const locale = useLocale();
const appConfig = useAppConfig();
const availability = useAvailability();
const business = useBusiness();
const i18n = useI18n();
const analytics = useBaseAnalytics();
const wareAnalytics = useWareAnalytics();

const getAvailabilityLabel = (ware) => {
  const result = availability.getWareLabelForWareListItem(business.isWareElectronicDistribution(ware), ware.stored, business.isWareOverride30days(ware), i18n);

  return result;
};

const getPriceLabel = (ware) => {

  let result = '';

  if (business.isWarePriceOnRequest(ware)) {
    result = i18n.t('Na dotaz');
  }
  else if (ware.price.minPrice?.price > 0) {
    if (ware.price.minPrice.price != ware.price.maxPrice.price) {
      result = i18n.t('od');
    }

    result += ' ' + ware.price.minPrice.priceFormatted;
  }

  return result;
};

const getPriceRecLabel = (ware) => {

  let result = '';

  if (!business.isWarePriceOnRequest(ware) && ware.price.minPrice?.price > 0 && ware.price.minPrice.price < ware.price.minPrice.recommended) {
    result = ware.price.minPrice.recommendedFormatted;
  }

  return result;
};

const selectItem = () => {
  wareAnalytics.getItems([props.item.id]).then(data => {

    // call view_item_list separately in blocks of approx. 10 items, otherwise the payload will be too large in some cases

    analytics.pushEvent('select_item', {
      ecommerce: {
        item_list_id: props.listId,
        item_list_name: props.listName,
        index: props.item.index,
        items: data
      }
    });
  });
};

const transformName = (name) => {
  let transformedName = name.replace(/-/g, '\u2011');
  let lastSpaceIndex = transformedName.lastIndexOf(' ');

  while (lastSpaceIndex > -1 && lastSpaceIndex > transformedName.length - 7) {
    transformedName = `${transformedName.substring(0, lastSpaceIndex)}\u00A0${transformedName.substring(lastSpaceIndex + 1)}`;
    lastSpaceIndex = transformedName.lastIndexOf(' ');
  }

  return transformedName;
};

</script>
<style lang="postcss">
.warelist__item {
  @apply flex-1 flex m-2;

  &:hover {
    @apply relative z-[1] no-underline;
  }

  &__standard {
    @apply relative flex-1 flex flex-col p-6 text-center bg-white shadow-sm transition-all ease-in-out;

    &:hover {
      @apply shadow-xl;
    }
  }

  &__image {
    @apply flex-1 flex items-center justify-center mb-4 desktop:mb-8;

    .ware-image--360::after {
      background-image: url('/img/360small.png');
    }
  }

  &__additional-image {
    @apply flex items-center justify-center max-w-[40px] aspect-square border border-neutral-300 bg-white;
  }

  &__name {
    @apply block my-3 text-lg tracking-normal;
  }

  &__info {
    @apply flex flex-wrap gap-y-4 items-end justify-center my-2;
  }

  &__price {
    @apply text-xl leading-[1rem] tracking-normal;
  }

  &__price-rec {
    @apply ml-3 pl-3 leading-[0.9rem] text-sm tracking-normal line-through border-l border-neutral-300;
  }

  &__availability {
    @apply ml-3 pl-3 leading-[0.9rem] uppercase tracking-normal text-success-600 border-l border-neutral-300;
  }

  .flip-box {
    @apply flex-1 flex;

    &__front {
      @apply flex-1 flex;
    }

    &__back {
      @apply flex-1 flex;
    }
  }

  &__stores {
    @apply mb-4;

    &>strong {
      @apply block mb-1 text-lg;
    }

    &>ul>li {
      @apply before:content-['\2014\a0'] before:mr-2;
    }
  }

  .variant-selector {
    @apply flex-1 flex flex-col gap-4 mb-4;

    &__groups strong,
    &__values strong {
      @apply block mb-1 text-lg;
    }

    &__groups__items {
      @apply items-stretch;
    }

    &__value {
      @apply text-sm;
    }

    &__group {
      @apply flex items-center justify-center w-full max-w-[46px] mr-[5px] mb-[5px] aspect-square border border-neutral-300 bg-white;
    }

  }
}
</style>